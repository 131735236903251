import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import FeedbackTable from "./components/FeedbackTable";

const FeedbackPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Feedback",
          href: "/feedback"
        }
      ])
    );
  }, [dispatch]);

  return (
    <>
      <FeedbackTable />
    </>
  );
};

export default FeedbackPage;
