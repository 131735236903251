/* eslint-disable react/display-name */
import React from "react";
import { Button } from "@amzn/awsui-components-react";
import { formatListItems } from "../../../utils";

export const COLUMN_DEFINITIONS = history => [
  {
    id: "id",
    header: () => "ID",
    cell: item => item.id,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "name",
    header: () => "Partner Name",
    cell: item => (
      <Button
        variant="link"
        onClick={() => history.push(`/partners/${item.id}`)}
      >
        {item.name}
      </Button>
    ),
    minWidth: "200px",
    allowLineWrap: true
  },
  {
    id: "emailDomain",
    cell: item => item.emailDomain.map(formatListItems),
    header: () => "Email Domain",
    minWidth: "140px",
    allowLineWrap: true
  },
  {
    id: "geo",
    header: () => "Geo",
    cell: item => item.geo,
    minWidth: "60px",
    allowLineWrap: true
  },
  {
    id: "headquarters",
    header: () => "HQ Location",
    cell: item => item.headquarters,
    minWidth: "60px",
    allowLineWrap: true
  },
  {
    id: "execSponsors",
    header: () => "Exec Sponsors",
    cell: item => {
      let execSponsors = 0;
      item.users.items.forEach(u => {
        if (u.execSponsor) execSponsors += 1;
      });
      return execSponsors;
    },
    minWidth: "30px",
    allowLineWrap: true
  },
  {
    id: "users",
    header: () => "Users",
    cell: item => {
      let users = 0;
      item.users.items.forEach(u => {
        if (!u.execSponsor) users += 1;
      });
      return users;
    },
    minWidth: "30px",
    allowLineWrap: true
  }
];

export const SORTABLE_COLUMNS = [
  { id: "name", field: "name" },
  { id: "emailDomain", field: "emailDomain" },
  { id: "geo", field: "geo" },
  { id: "headquarters", field: "headquarters" },
  {
    id: "execSponsors",
    comparator: (item1, item2) => {
      let item1execsponsors = 0;
      let item2execsponsors = 0;
      item1.users.items.forEach(u => {
        if (u.execSponsor) item1execsponsors += 1;
      });
      item2.users.items.forEach(u => {
        if (u.execSponsor) item2execsponsors += 1;
      });
      if (item1execsponsors === item2execsponsors) return 0;
      else return item1execsponsors > item2execsponsors ? 1 : -1;
    }
  },
  {
    id: "users",
    comparator: (item1, item2) => {
      let item1users = 0;
      let item2users = 0;
      item1.users.items.forEach(u => {
        if (!u.execSponsor) item1users += 1;
      });
      item2.users.items.forEach(u => {
        if (!u.execSponsor) item2users += 1;
      });
      if (item1users === item2users) return 0;
      else return item1users > item2users ? 1 : -1;
    }
  }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Table settings",
    options: [
      { id: "id", label: "ID", editable: false, visible: false },
      { id: "name", label: "Name", editable: true, visible: true },
      {
        id: "emailDomain",
        label: "Email Domain",
        editable: true,
        visible: false
      },
      { id: "geo", label: "Geo", editable: true, visible: true },
      {
        id: "headquarters",
        label: "HQ Location",
        editable: true,
        visible: true
      },
      {
        id: "execSponsors",
        label: "Exec Sponsors",
        editable: true,
        visible: false
      },
      { id: "users", label: "Users", editable: true, visible: false }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" }
];

export const FILTERING_OPTIONS = [
  {
    groupValuesLabel: "Name Values",
    propertyKey: "name",
    propertyLabel: "Partner Name",
    values: []
  },
  {
    groupValuesLabel: "Geo Values",
    propertyKey: "geo",
    propertyLabel: "Geo",
    values: ["NA", "APAC", "China", "EMEA", "Japan", "LATAM"]
  }
];
