import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import HomeDashboard from "./components/HomeDashboard";

import "../../styles/home.scss";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        }
      ])
    );
  }, [dispatch]);

  return <HomeDashboard />;
};

export default HomePage;
