export const AWSTEAM_COLUMN_DEFINITIONS = [
  {
    id: "role",
    header: () => "Role",
    cell: item => item.role,
    minWidth: "50px",
    allowLineWrap: false
  },
  {
    id: "name",
    header: () => "Name",
    cell: item => item.name,
    minWidth: "180px",
    allowLineWrap: true
  },
  {
    id: "email",
    header: () => "Email",
    cell: item => item.email,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "phoneno",
    header: () => "Phone",
    cell: item => item.phoneno,
    minWidth: "100px",
    allowLineWrap: false
  }
];
