import React, { useState, useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import {
  createCognitoUser,
  resendCognitoConfirmation,
  addUserToGroup,
  updateUser,
  disableUserAccount
} from "../../../graphql/mutations";
import { getPartnerUsers2 } from "../../../graphql/apnid-queries";

import { addFlash } from "../../../actions";

import { addPartnerUserForm } from "../../../reducers";

import {
  Button,
  ButtonDropdown,
  ColumnLayout,
  Modal,
  Table,
  TableSelection,
  TableFiltering,
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableContentSelector,
  TableWrapLines,
  Form,
  FormField,
  Input,
  Select
} from "@amzn/awsui-components-react";

import {
  USERS_COLUMN_DEFINITIONS,
  USERS_SORTABLE_COLUMNS,
  USERS_PAGE_SELECTOR_OPTIONS,
  USERS_CONTENT_SELECTOR_OPTIONS
} from "./PartnerUsersTableConfig";

const validator = require("validator");

const DEFAULT_STATE = {
  fieldValues: {
    newUserEmail: "",
    emailDomain: []
  },
  fieldStates: {
    newUserEmail: false,
    emailDomain: false
  },
  requiredFields: ["newUserEmail", "emailDomain"]
};

// New user invitation form
const InviteNewUserModal = ({ emailDomains, onCancel, onSubmit }) => {
  const [form, setForm] = useReducer(addPartnerUserForm, DEFAULT_STATE);

  const domainOptions = () => {
    let options = [];
    for (let i = 0; i < emailDomains.length; i += 1) {
      let domain = emailDomains[i];
      options.push({
        id: i.toString(),
        label: domain
      });
    }
    return options;
  };

  const formIsValid = () => {
    if (
      form.fieldValues.emailDomain.length === 0 ||
      form.fieldValues.newUserEmail === ""
    )
      return false;
    else {
      const checkEmail = `${form.fieldValues.newUserEmail}${form.fieldValues.emailDomain.label}`;
      return validator.isEmail(checkEmail) ? true : false;
    }
  };

  return (
    <Modal
      header="Invite new user"
      visible={true}
      expandToFit={true}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!formIsValid()}
            onClick={() => {
              if (formIsValid()) onSubmit(form.fieldValues);
            }}
          >
            Send Invitation
          </Button>
        </span>
      }
    >
      <Form>
        <FormField>
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <FormField label="Email Alias" description="Alias only.">
                <Input
                  id="newUserEmail"
                  placeholder="User email alias"
                  value={form.fieldValues.newUserEmail}
                  onInput={({ detail }) =>
                    setForm({
                      type: "changeInput",
                      payload: {
                        field: "newUserEmail",
                        required: true,
                        value: detail.value
                      }
                    })
                  }
                />
              </FormField>
              <FormField
                label="Email Domain"
                description="Select the domain to append."
              >
                <Select
                  options={domainOptions()}
                  selectedOption={form.fieldValues.emailDomain}
                  ariaRequired={true}
                  onChange={({ detail }) =>
                    setForm({
                      type: "changeSelect",
                      payload: {
                        field: "emailDomain",
                        required: true,
                        value: detail.selectedOption
                      }
                    })
                  }
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormField>
      </Form>
    </Modal>
  );
};

const PartnerUsersTable = ({ partnerId }) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [filteringText, setFilteringText] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [emailDomains, setEmailDomains] = useState([]);
  const [modal, setModal] = useState(null);

  const dispatch = useDispatch();

  // Get data on component load
  useEffect(() => {
    fetchData("");
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async nextToken => {
    setLoading(true);
    let params = {
      id: partnerId,
      limit: 1000,
      userFilter: {
        execSponsor: { ne: true },
        userStatus: { ne: "disabled" }
      }
    };
    if (nextToken) params.nextToken = nextToken;

    const result = await API.graphql(
      graphqlOperation(getPartnerUsers2, params)
    );
    setUsers(users => users.concat(result.data.getPartner.users.items));

    if (result.data.getPartner.users.nextToken) {
      fetchData(result.data.getPartner.users.nextToken);
    } else {
      setEmailDomains(result.data.getPartner.emailDomain);
      setLoading(false);
    }
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  // Updates the filtering text
  const onFilteringChange = ({ detail: { filteringText } }) => {
    setFilteringText(filteringText);
  };

  // Resets the filtering text
  const clearFilter = () => {
    setFilteringText("");
  };

  // Sets the selected user row
  const onSelectedUserChange = event => {
    setSelectedUser(event.detail.selectedItems);
  };

  // Handle actions from the dropdown button
  const actionItemClicked = async event => {
    const thisUser = selectedUser[0];

    if (event && event.detail) {
      switch (event.detail.id) {
        case "disable":
          try {
            const input = { input: { username: thisUser.id } };
            await API.graphql(graphqlOperation(disableUserAccount, input));

            // Display success message
            dispatch(
              addFlash({
                type: "success",
                content: `${thisUser.email} disabled.`
              })
            );

            // Refresh this view
            setUsers([]);
            fetchData("");
          } catch (err) {
            dispatch(
              addFlash({
                type: "error",
                header: "An error occurred.",
                content: err.errors[0].message
              })
            );
          }
          break;
        case "promote":
          try {
            // Update the users group memberships in Cognito
            const input = {
              input: {
                username: thisUser.id,
                groupName: "PartnerExecutiveSponsor"
              }
            };
            await API.graphql(graphqlOperation(addUserToGroup, input));

            // Update the users DynamoDB profile
            await API.graphql(
              graphqlOperation(updateUser, {
                input: {
                  id: thisUser.id,
                  execSponsor: true
                }
              })
            );

            // Display success message
            dispatch(
              addFlash({
                type: "success",
                content: `${thisUser.email} promoted to Executive Sponsor.`
              })
            );

            // Refresh this view
            setUsers([]);
            fetchData("");
          } catch (err) {
            dispatch(
              addFlash({
                type: "error",
                header: "An error occurred.",
                content: err.errors[0].message
              })
            );
          }
          break;
        case "resend":
          try {
            const input = { input: { email: thisUser.email } };
            await API.graphql(
              graphqlOperation(resendCognitoConfirmation, input)
            );
            dispatch(
              addFlash({
                type: "success",
                content: `An new invitation email will be resent to ${thisUser.email}`
              })
            );
          } catch (err) {
            dispatch(
              addFlash({
                type: "error",
                header: "An error occurred.",
                content: err.errors[0].message
              })
            );
          }
          break;
        default:
          break;
      }
    }
  };

  // Sets the action button items
  const actionButtons = [
    {
      text: "Disable",
      id: "disable"
    },
    {
      text: "Promote",
      id: "promote",
      disabled: selectedUser.length
        ? selectedUser[0].firstName
          ? false
          : true
        : true
    },
    {
      text: "Resend Invite",
      id: "resend",
      disabled: selectedUser.length
        ? !selectedUser[0].firstName
          ? false
          : true
        : true
    }
  ];

  // Handle modal cancellation
  const onInviteNewUserModalCancel = () => {
    setModal(null);
  };

  // Handle modal submission
  const onInviteNewUserModalSubmit = form => {
    setModal(null);
    sendNewUserInvitation(`${form.newUserEmail}${form.emailDomain.label}`);
  };

  // Send new user invitation
  const sendNewUserInvitation = async email => {
    try {
      // Create the user in Cognito
      const input = {
        input: {
          email,
          partnerId
        }
      };

      const result = await API.graphql(
        graphqlOperation(createCognitoUser, input)
      );

      const newUserObject = {
        id: result.data.createCognitoUser,
        email,
        userStatus: "invited",
        firstName: null,
        lastName: null,
        phoneno: null,
        title: null,
        certifications: null,
        coverageAreas: null,
        execSponsor: null
      };

      setUsers(users.concat(newUserObject));
      dispatch(
        addFlash({
          type: "success",
          content: `An invitation has been sent to ${email}`
        })
      );
    } catch (err) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred.",
          content: err.errors[0].message
        })
      );
    }
  };

  return (
    <div>
      {modal}
      <div className="awsui-util-mb-l">
        <Table
          columnDefinitions={USERS_COLUMN_DEFINITIONS}
          loading={loading}
          loadingText="Loading..."
          items={users}
          resizableColumns={true}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>Users</h2>
              </div>
              <div className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  items={actionButtons}
                  onItemClick={e => actionItemClicked(e)}
                  disabled={selectedUser.length ? false : true}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  icon="add-plus"
                  onClick={() => {
                    setModal(
                      <InviteNewUserModal
                        emailDomains={emailDomains}
                        onCancel={onInviteNewUserModalCancel}
                        onSubmit={onInviteNewUserModalSubmit}
                      />
                    );
                  }}
                >
                  Invite
                </Button>
              </div>
            </div>
          }
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No Users</b>
              </div>
              <p className="awsui-util-mb-s">No Users to display.</p>
            </div>
          }
          noMatch={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-xs awsui-util-mb-xs">
                <b>No matches</b>
              </div>
              <p className="awsui-util-mb-s">No results match your query</p>
              <div className="awsui-util-mb-l">
                <Button onClick={clearFilter.bind(this)}>Clear filter</Button>
              </div>
            </div>
          }
        >
          <TableFiltering
            filteringPlaceholder="Search users"
            filteringText={filteringText}
            onFilteringChange={onFilteringChange.bind(this)}
          />
          <TablePagination
            onPaginationChange={onPaginationChange.bind(this)}
            pageSize={pageSize}
          />
          <TableSorting
            sortingColumn="name"
            sortableColumns={USERS_SORTABLE_COLUMNS}
          />
          <TableSelection
            selectionType="single"
            selectedItems={selectedUser}
            onSelectionChange={onSelectedUserChange.bind(this)}
          />
          <TablePreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
          >
            <TablePageSizeSelector
              title="Page size"
              options={USERS_PAGE_SELECTOR_OPTIONS}
            />
            <TableWrapLines
              label="Wrap lines"
              description="Check to see all the text and wrap the lines"
              value={false}
            />
            <TableContentSelector
              title="Select visible columns"
              options={USERS_CONTENT_SELECTOR_OPTIONS}
            />
          </TablePreferences>
        </Table>
      </div>
    </div>
  );
};

export default PartnerUsersTable;
