import React from "react";

import { Table } from "@amzn/awsui-components-react";

import { COLUMN_DEFINITIONS } from "./CsatResultsTableConfig.jsx";

const CsatResultsTable = ({ csatFeedback, loading }) => {
  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      items={csatFeedback}
      loading={loading}
      loadingText="Loading ..."
      header={
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>CSAT Feedback</h2>
          </div>
        </div>
      }
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No CSAT results</b>
          </div>
          <p className="awsui-util-mb-s">No CSAT results to display.</p>
        </div>
      }
    />
  );
};

export default CsatResultsTable;
