import React from "react";

export const COLUMN_DEFINITIONS = [
  {
    id: "question",
    header: () => "Question",
    cell: item =>
      item.question.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
        return str.toUpperCase();
      }),
    minWidth: "50px",
    allowLineWrap: true
  },
  {
    id: "score",
    header: () => "Score",
    cell: item => item.score,
    minWidth: "30px",
    allowLineWrap: true
  },
  {
    id: "comments",
    header: () => "Comments",
    cell: item =>
      !item.comments.length
        ? "-"
        : item.comments.map((c, k) => <p key={c + k}>{c}</p>),
    minWidth: "150px",
    allowLineWrap: true
  }
];
