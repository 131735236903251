/**
 * My custom queries
 */

export const getPartnerUsers = `query GetPartner(
  $id: ID!
  $limit: Int
) {
  getPartner(id: $id) {
    id
    users(limit: $limit) {
      items {
        id
      }
    }
  }
}
`;

export const countAllPartnerEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
    }
    nextToken
  }
}
`;

export const eventsByPartnerLastYear = `query EventsByPartnerByDate(
  $partnerId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByPartnerByDate(
    partnerId: $partnerId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date
    }
    nextToken
  }
}
`;

export const getOrganization = `query GetPartner(
  $id: ID!
  $limit: Int
  $nextToken: String
) {
  getPartner(id: $id) {
    id
    spmsId
    name
    emailDomain
    headquarters
    coverageAreas
    specialtyDomains
    users(limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
      }
      nextToken
    }
  }
}
`;

export const getPartnerData = `query GetPartner(
  $id: ID!
  $userFilter: ModelUserFilterInput
  $nextToken: String
) {
  getPartner(id: $id) {
    id
    spmsId
    name
    emailDomain
    headquarters
    geo
    coverageAreas
    specialtyDomains
    awsTeam {
      role
      name
      email
      phoneno
    }
    users(limit: 100000, nextToken: $nextToken, filter: $userFilter) {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        userStatus
      }
      nextToken
    }
  }
}
`;

export const getPartnerDetail = `query GetPartner(
  $id: ID!
) {
  getPartner(id: $id) {
    id
    spmsId
    name
    emailDomain
    headquarters
    geo
    coverageAreas
    specialtyDomains
  }
}
`;

export const getPartnerAwsTeam = `query GetPartner(
  $id: ID!
) {
  getPartner(id: $id) {
    awsTeam {
      role
      name
      email
      phoneno
    }
  }
}
`;

export const getPartnerUsers2 = `query GetPartner(
  $id: ID!
  $userFilter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getPartner(id: $id) {
    emailDomain
    users(limit: $limit, nextToken: $nextToken, filter: $userFilter) {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        userStatus
        createdAt
      }
      nextToken
    }
  }
}
`;

// export const listAllPartners = `query ListPartners(
//   $filter: ModelPartnerFilterInput
//   $limit: Int
//   $nextToken: String
//   $userFilter: ModelUserFilterInput
//   $userLimit: Int
//   $userNextToken: String
// ) {
//   listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       spmsId
//       name
//       geo
//       emailDomain
//       headquarters
//       coverageAreas
//       specialtyDomains
//       users(limit: $userLimit, nextToken: $userNextToken, filter: $userFilter) {
//         items {
//           firstName
//           lastName
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;

export const listAllPartners = `query ListPartners(
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      spmsId
      name
      geo
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      awsTeam {
        role
      }
      users(limit: 100000) {
        items {
          id
          execSponsor
          userStatus
        }
      }
    }
    nextToken
  }
}
`;

export const listCalendarEvents = `query ListCalendarEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partner {
        id
        name
      }
      user {
        firstName
        lastName
        email
      }
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      awspsa
      oppty
    }
    nextToken
  }
}
`;

export const listEventsWithDetail = `query ListCalendarEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partner {
        id
        name
      }
      user {
        firstName
        lastName
        email
      }
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      deliveryMethod
      multiCustomer
      comment
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      awspsa
      oppty
    }
    nextToken
  }
}
`;

export const listEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partner {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
      }
      partnerId
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
      }
      userId
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      awspsa
      oppty
    }
    nextToken
  }
}
`;
