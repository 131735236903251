// form reducers
import addAwsTeamMemberForm from "./addAwsTeamMemberForm";
import addPartnerUserForm from "./addPartnerUserForm";
import createPartnerForm from "./createPartnerForm";
import editPartnerDetailForm from "./editPartnerDetailForm";

// app-wide shared reducers
import app from "./app";
// import currentUser from "./currentUser";
import { combineReducers, createStore } from "redux";

export {
  addAwsTeamMemberForm,
  addPartnerUserForm,
  createPartnerForm,
  editPartnerDetailForm
};

const rootReducer = combineReducers({
  app
  // currentUser
});

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
