import produce from "immer";
import { objIsEmpty } from "../utils";

const validator = require("validator");

const createPartnerForm = (form, action) => {
  const field = action.payload.field;
  const value = action.payload.value;
  let validState;

  return produce(form, draft => {
    switch (action.type) {
      case "change":
        if (action.required) {
          if (typeof value === "string")
            validState = !validator.isEmpty(value) ? true : false;
          else if (typeof value === "object")
            validState = !objIsEmpty(value) ? true : false;
          else validState = false;

          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value;
        break;

      case "addToken":
        let addTokenList = [...form.fieldValues[field]];
        addTokenList.push({
          label: value,
          iconName: field === "emailDomain" ? "envelope" : null
        });
        let addTokenInputKey = `${field}Input`;

        if (action.required) {
          validState = addTokenList.length >= 1 ? true : false;
          draft.fieldStates[field] = validState;
        }

        draft.fieldValues[field] = addTokenList;
        draft.fieldValues[addTokenInputKey] =
          field === "emailDomain" ? "@" : "";
        break;

      case "dismissToken":
        let dismissTokenList = [...form.fieldValues[field]];
        dismissTokenList.splice(value, 1);

        if (action.required) {
          validState = dismissTokenList.length >= 1 ? true : false;
          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = dismissTokenList;
        break;

      case "reset":
      default:
        draft = action.payload;
        break;
    }
  });
};

export default createPartnerForm;
