import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Table,
  TableContentSelector,
  TableFiltering,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSelection,
  TableSorting,
  TableWrapLines
} from "@amzn/awsui-components-react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from "./FeedbackTableConfig.jsx";

import { API, graphqlOperation } from "aws-amplify";
import { listFeedback } from "../../../graphql/queries";
import { deleteFeedback } from "../../../graphql/mutations";

import { addFlash } from "../../../actions";

const FeedbackPage = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [filteringText, setFilteringText] = useState("");
  const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);

  const dispatch = useDispatch();

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const result = await API.graphql(
      graphqlOperation(listFeedback, { limit: 100 })
    );
    setFeedback(result.data.listFeedback.items);
    setLoading(false);
  };

  // Keeps track of how many feedback rows are selected
  const headerCounter = (selectedFeedback, feedback) => {
    return selectedFeedback.length
      ? `(${selectedFeedback.length} of ${feedback.length})`
      : `(${feedback.length})`;
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  // Updates the filtering text
  const onFilteringChange = ({ detail: { filteringText } }) => {
    setFilteringText(filteringText);
  };

  // Resets the filtering text
  const clearFilter = () => {
    setFilteringText("");
  };

  // Sets the selected feedback rows
  const onSelectedFeedbackChange = event => {
    setSelectedFeedback(event.detail.selectedItems);
  };

  // Delete the selected feedback
  const deleteSelectedFeedback = () => {
    setDeleteFeedbackLoading(true);

    if (selectedFeedback.length) {
      selectedFeedback.forEach(async row => {
        try {
          await API.graphql(
            graphqlOperation(deleteFeedback, { input: { id: row.id } })
          );
          fetchData();
          dispatch(
            addFlash({
              type: "success",
              content: "Feedback deleted."
            })
          );
        } catch (err) {
          dispatch(
            addFlash({
              type: "error",
              header: "An error occurred.",
              content: err.errors[0].message
            })
          );
        }
      });
    }

    setDeleteFeedbackLoading(false);
  };

  // Refresh function
  const refreshFn = () => {
    setFeedback([]);
    fetchData("");
  };

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      items={feedback}
      loading={loading}
      loadingText="Loading..."
      header={
        <FeedbackTableHeader
          selectedFeedback={selectedFeedback}
          counter={headerCounter(selectedFeedback, feedback)}
          refreshFn={refreshFn}
          deleteFn={deleteSelectedFeedback}
          deleteState={deleteFeedbackLoading}
        />
      }
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No feedback</b>
          </div>
          <p className="awsui-util-mb-s">No feedback to display.</p>
        </div>
      }
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">No results match your query</p>
          <div className="awsui-util-mb-l">
            <Button onClick={clearFilter.bind(this)}>Clear filter</Button>
          </div>
        </div>
      }
    >
      <TableFiltering
        filteringPlaceholder="Search feedback"
        filteringText={filteringText}
        onFilteringChange={onFilteringChange.bind(this)}
      />
      <TablePagination
        onPaginationChange={onPaginationChange.bind(this)}
        pageSize={pageSize}
      />
      <TableSorting sortableColumns={SORTABLE_COLUMNS} />
      <TableSelection
        selectionType="multi"
        selectedItems={selectedFeedback}
        onSelectionChange={onSelectedFeedbackChange.bind(this)}
      />
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={PAGE_SELECTOR_OPTIONS}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Check to see all the text and wrap the lines"
          value={false}
        />
        <TableContentSelector
          title="Select visible columns"
          options={CONTENT_SELECTOR_OPTIONS}
        />
      </TablePreferences>
    </Table>
  );
};

const FeedbackTableHeader = ({
  selectedFeedback,
  counter,
  refreshFn,
  deleteFn,
  deleteState
}) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>
          Feedback&nbsp;
          {counter ? (
            <span className="awsui-util-header-counter">{counter}</span>
          ) : (
            ""
          )}
        </h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" onClick={() => refreshFn()} />
        <Button
          icon="close"
          loading={deleteState}
          disabled={selectedFeedback.length === 0}
          onClick={() => deleteFn()}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default FeedbackPage;
