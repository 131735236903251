import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { addBreadCrumb } from "../../actions";

import EventDetails from "./components/EventDetails";

import "../../styles/eventStyles.scss";

const EventDetailPage = props => {
  const { match } = props;
  const { eventId } = match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      addBreadCrumb({
        text: "Event Details",
        href: null
      })
    );
  }, [dispatch]);

  return (
    <>
      <EventDetails eventId={eventId} />
    </>
  );
};

export default EventDetailPage;
