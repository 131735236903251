import React, { useState, useEffect } from "react";

import { API, graphqlOperation } from "aws-amplify";
import { listEvents } from "../../../graphql/queries";

import {
  Table,
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableContentSelector,
  TableWrapLines
} from "@amzn/awsui-components-react";

import {
  COLUMN_DEFINITIONS,
  SORTABLE_COLUMNS,
  PAGE_SELECTOR_OPTIONS,
  CONTENT_SELECTOR_OPTIONS
} from "./PartnerEventsTableConfig";

const PartnerEventsTable = ({ partnerId, eventStatus, history }) => {
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  // Get data on component load
  useEffect(() => {
    fetchData("");
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async nextToken => {
    setLoading(true);
    let params = {
      limit: 1000,
      filter: {
        partnerId: { eq: partnerId },
        status: { eq: eventStatus }
      }
    };
    if (nextToken) params.nextToken = nextToken;

    const result = await API.graphql(graphqlOperation(listEvents, params));
    setEvents(events => events.concat(result.data.listEvents.items));

    if (result.data.listEvents.nextToken) {
      fetchData(result.data.listEvents.nextToken);
    } else {
      setLoading(false);
    }
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  return (
    <div>
      <div className="awsui-util-mb-l">
        <Table
          columnDefinitions={COLUMN_DEFINITIONS(history, eventStatus)}
          loading={loading}
          loadingText="Loading..."
          items={events}
          resizableColumns={true}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>{eventStatus} Events</h2>
              </div>
            </div>
          }
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No Events</b>
              </div>
              <p className="awsui-util-mb-s">No events to display.</p>
            </div>
          }
        >
          <TablePagination
            onPaginationChange={onPaginationChange.bind(this)}
            pageSize={pageSize}
          />
          <TableSorting
            sortingColumn="name"
            sortableColumns={SORTABLE_COLUMNS}
          />
          <TablePreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
          >
            <TablePageSizeSelector
              title="Page size"
              options={PAGE_SELECTOR_OPTIONS}
            />
            <TableWrapLines
              label="Wrap lines"
              description="Check to see all the text and wrap the lines"
              value={false}
            />
            <TableContentSelector
              title="Select visible columns"
              options={CONTENT_SELECTOR_OPTIONS}
            />
          </TablePreferences>
        </Table>
      </div>
    </div>
  );
};

export default PartnerEventsTable;
