import produce from "immer";
import { objIsEmpty } from "../utils";

const validator = require("validator");

const addAwsTeamMemberForm = (form, action) => {
  const field = action.payload.field;
  const value = action.payload.value;

  return produce(form, draft => {
    switch (action.type) {
      case "changeInput":
        if (action.payload.required)
          draft.fieldStates[field] = !validator.isEmpty(value) ? true : false;
        draft.fieldValues[field] = value;
        break;

      case "changeSelect":
        if (action.payload.required)
          draft.fieldStates[field] = !objIsEmpty(value) ? true : false;
        draft.fieldValues[field] = value;
        break;

      case "changeEmail":
        draft.fieldStates[field] = validator.isEmail(value) ? true : false;
        draft.fieldValues[field] = value;
        break;

      case "changePhone":
        draft.fieldStates[field] =
          validator.isMobilePhone(value.replace(/\s/g, ""), "any", {
            strictMode: false
          }) || value === ""
            ? true
            : false;
        draft.fieldValues[field] = value;
        break;

      default:
        draft = action.payload;
        break;
    }
  });
};

export default addAwsTeamMemberForm;
