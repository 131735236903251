import React, { useState, useEffect } from "react";

import { API, graphqlOperation } from "aws-amplify";
import { getEvent } from "../../../graphql/queries";

import { ColumnLayout, Spinner } from "@amzn/awsui-components-react";

import { formatDate, formatListItems, objIsEmpty } from "../../../utils";

import CsatResultsTable from "./CsatResultsTable";

const EventDetails = ({ eventId }) => {
  const [loading, setLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState(false);

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const result = await API.graphql(
      graphqlOperation(getEvent, { id: eventId })
    );
    let detail = result.data.getEvent;
    setEventDetail(detail);
    setLoading(false);
  };

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Event Details</h2>
            </div>
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(eventDetail) && (
          <ColumnLayout columns={3} variant="text-grid">
            <div data-awsui-column-layout-root="true">
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Partner Name</div>
                    <div>{eventDetail.partner.name}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Event Owner</div>
                    <div>{`${eventDetail.user.firstName} ${eventDetail.user.lastName}`}</div>
                    <div>
                      <a href={`mailto:${eventDetail.user.email}`}>
                        {eventDetail.user.email}
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Topic</div>
                    <div>{eventDetail.topic}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Date</div>
                    <div>{formatDate(eventDetail.date)}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Geo</div>
                    <div>{eventDetail.geo}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Location</div>
                    <div>{eventDetail.location}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Registration Count</div>
                    <div>
                      {eventDetail.registrationCount
                        ? eventDetail.registrationCount
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Attendee Count</div>
                    <div>
                      {eventDetail.attendeeCount
                        ? eventDetail.attendeeCount
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ColumnLayout>
        )}
      </div>

      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Customer(s)</h2>
            </div>
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(eventDetail) && (
          <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root="true">
              {eventDetail.customers.map(formatListItems)}
            </div>
          </ColumnLayout>
        )}
      </div>

      <CsatResultsTable
        loading={loading}
        csatFeedback={
          !objIsEmpty(eventDetail) && eventDetail.csat.feedback
            ? eventDetail.csat.feedback
            : []
        }
      />
    </>
  );
};

export default EventDetails;
