import { formatListItems, certificationLabelLookup } from "../../../utils";

export const EXECSPONSORS_COLUMN_DEFINITIONS = [
  {
    id: "name",
    header: () => "Name",
    cell: item => `${item.firstName} ${item.lastName}`,
    minWidth: "150px",
    allowLineWrap: true
  },
  {
    id: "email",
    header: () => "Email",
    cell: item => item.email,
    minWidth: "150px",
    allowLineWrap: true
  },
  {
    id: "title",
    header: () => "Title",
    cell: item => item.title,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "certifications",
    header: () => "Certifications",
    cell: item =>
      item.certifications
        ? item.certifications.map(certificationLabelLookup).map(formatListItems)
        : "-",
    minWidth: "350px",
    allowLineWrap: true
  },
  {
    id: "coverageAreas",
    header: () => "Coverage Areas",
    cell: item =>
      item.coverageAreas ? item.coverageAreas.map(formatListItems) : "-",
    minWidth: "100px",
    allowLineWrap: false
  }
];
