import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import EditPartnerDetailsForm from "./components/EditPartnerDetailForm";

import "../../styles/partnerStyles.scss";

const EditPartnerDetailPage = props => {
  const { match } = props;
  const { partnerId } = match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Partners",
          href: "/partners"
        },
        {
          text: "Partner Details",
          href: `/partners/${partnerId}`
        },
        {
          text: "Edit",
          href: null
        }
      ])
    );
  }, [dispatch, partnerId]);

  return (
    <>
      <EditPartnerDetailsForm partnerId={partnerId} history={props.history} />
    </>
  );
};

export default EditPartnerDetailPage;
