import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// aws, amplify, and util components
import { Auth as AmplifyAuth, Hub } from "aws-amplify";
import { Spinner } from "@amzn/awsui-components-react";

import {
  setUserIsAuthenticated,
  setCurrentUser,
  userReset
} from "../../actions";

export const Login = props => {
  const { history } = props;

  const userIsAuthenticated = useSelector(
    state => state.app.userIsAuthenticated
  );

  userIsAuthenticated
    ? history.push("/")
    : AmplifyAuth.federatedSignIn({ provider: "AmazonCorp" });

  return null;
};

export const LoginRedirect = () => {
  const dispatch = useDispatch();

  const userIsAuthenticated = useSelector(
    state => state.app.userIsAuthenticated
  );

  useEffect(() => {
    // User is already authenticated, redirect them to the home page
    if (userIsAuthenticated) window.location.href = "/";
  }, [userIsAuthenticated]);

  const authListener = async ({ payload }) => {
    switch (payload.event) {
      case "signIn":
        dispatch(setCurrentUser(await AmplifyAuth.currentAuthenticatedUser()));
        dispatch(setUserIsAuthenticated(true));
        window.location.href = "/";
        break;

      case "signIn_failure":
        dispatch(setUserIsAuthenticated(false));
        break;

      default:
        break;
    }
  };

  Hub.listen("auth", authListener);

  return (
    <span aria-label="Loading">
      <Spinner size="large" />
    </span>
  );
};

export const Logout = async () => {
  const dispatch = useDispatch();
  dispatch(userReset());
  dispatch(setUserIsAuthenticated(false));
  await AmplifyAuth.signOut();
  return null;
};
