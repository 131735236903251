import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { getPartnerDetail } from "../../../graphql/apnid-queries";

import { addFlash } from "../../../actions";

import { Button, ColumnLayout, Spinner } from "@amzn/awsui-components-react";

import { formatListItems, objIsEmpty } from "../../../utils";

const PartnerDetails = ({ partnerId, history }) => {
  const [loading, setLoading] = useState(false);
  const [partnerDetail, setPartnerDetail] = useState(false);

  const dispatch = useDispatch();

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const result = await API.graphql(
      graphqlOperation(getPartnerDetail, { id: partnerId })
    );
    let detail = result.data.getPartner;
    if (detail) {
      if (!detail.headquarters) detail.headquarters = "";
      if (!detail.coverageAreas) detail.coverageAreas = [];
      if (!detail.specialtyDomains) detail.specialtyDomains = [];
      setPartnerDetail(detail);
      setLoading(false);
    } else {
      dispatch(
        addFlash({
          type: "error",
          content: `Partner not found with id: ${partnerId}`
        })
      );
      history.push("/partners");
    }
  };

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Partner Details</h2>
            </div>
            {!loading && (
              <div className="awsui-util-action-stripe-group">
                <Button
                  icon="external"
                  href={`https://apn-portal.my.salesforce.com/${partnerDetail.id}`}
                  iconAlign="right"
                >
                  View in APN Portal
                </Button>
                {/* <Button icon="edit" href={`/partners/${partnerDetail.id}/edit`}>Edit</Button> */}
                <Button
                  icon="edit"
                  onClick={() =>
                    history.push(`/partners/${partnerDetail.id}/edit`)
                  }
                >
                  Edit
                </Button>
              </div>
            )}
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(partnerDetail) && (
          <ColumnLayout columns={4} variant="text-grid">
            <div data-awsui-column-layout-root="true">
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Partner Name</div>
                    <div>{partnerDetail.name}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">SPMS ID</div>
                    <div>
                      {partnerDetail.spmsId ? partnerDetail.spmsId : "-"}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">
                      Headquarters Location
                    </div>
                    <div>{partnerDetail.headquarters}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Partner Geo</div>
                    <div>{partnerDetail.geo}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Email Domains</div>
                    <div>
                      {partnerDetail.emailDomain.length
                        ? partnerDetail.emailDomain.map(formatListItems)
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Coverage Areas</div>
                    <div>
                      {partnerDetail.coverageAreas.length
                        ? partnerDetail.coverageAreas.map(formatListItems)
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Specialty Domains</div>
                    <div>
                      {partnerDetail.specialtyDomains.length
                        ? partnerDetail.specialtyDomains.map(formatListItems)
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ColumnLayout>
        )}
      </div>
    </>
  );
};

export default PartnerDetails;
