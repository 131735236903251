import React, { useState, useEffect } from "react";

import {
  Button,
  Table,
  TableContentSelector,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TablePropertyFiltering,
  TableSorting,
  TableWrapLines
} from "@amzn/awsui-components-react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  FILTERING_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from "./PartnersTableConfig";

import { API, graphqlOperation } from "aws-amplify";
import { listAllPartners } from "../../../graphql/apnid-queries";

const PartnersTable = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [pageSize, setPageSize] = useState(30);

  // Get data on page load
  useEffect(() => {
    fetchData("");
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async nextToken => {
    setLoading(true);
    let params = { limit: 1000 };
    if (nextToken) params.nextToken = nextToken;

    const result = await API.graphql(graphqlOperation(listAllPartners, params));
    setPartners(partners => partners.concat(result.data.listPartners.items));

    if (result.data.listPartners.nextToken) {
      fetchData(result.data.listPartners.nextToken);
    } else {
      setLoading(false);
    }
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  // Refresh function
  const refreshFn = () => {
    setPartners([]);
    fetchData("");
  };

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS(history)}
      items={partners}
      resizableColumns={true}
      loading={loading}
      loadingText="Loading..."
      header={<PartnersTableHeader refreshFn={refreshFn} />}
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No partners</b>
          </div>
          <p className="awsui-util-mb-s">No partners to display.</p>
        </div>
      }
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">No results match your filter.</p>
        </div>
      }
    >
      <TablePropertyFiltering
        filteringOptions={FILTERING_OPTIONS}
        groupValuesText="Values"
        groupPropertiesText="Properties"
        operationAndText="and"
        operationNotAndText="and not"
        operationOrText="or"
        operationNotOrText="or not"
        clearFiltersText="Clear filter"
        placeholder="Filter partners by property or value"
        allowFreeTextFiltering={true}
        filteringCountTextFunction={count =>
          `${count} ${count === 1 ? "match" : "matches"}`
        }
      />
      <TablePagination
        onPaginationChange={onPaginationChange.bind(this)}
        pageSize={pageSize}
      />
      <TableSorting sortingColumn="name" sortableColumns={SORTABLE_COLUMNS} />
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={PAGE_SELECTOR_OPTIONS}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Check to see all the text and wrap the lines"
          value={false}
        />
        <TableContentSelector
          title="Select visible columns"
          options={CONTENT_SELECTOR_OPTIONS}
        />
      </TablePreferences>
    </Table>
  );
};

const PartnersTableHeader = ({ refreshFn }) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>APN Immersion Day Partners</h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" onClick={() => refreshFn()}>
          Refresh
        </Button>
        <Button
          icon="add-plus"
          variant="primary"
          onClick={() => (window.location.href = "/create-partner")}
        >
          Create new
        </Button>
      </div>
    </div>
  );
};

export default PartnersTable;
