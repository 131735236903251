import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import EventsCalendar from "./components/EventsCalendar";

import "../../styles/eventStyles.scss";

const EventsCalendarPage = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Events Calendar",
          href: "/events-calendar"
        }
      ])
    );
  }, [dispatch]);

  return (
    <>
      <EventsCalendar history={props.history} />
    </>
  );
};

export default EventsCalendarPage;
