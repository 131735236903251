import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import { Tabs } from "@amzn/awsui-components-react";

import PartnerDetails from "./components/PartnerDetails";
import PartnerAwsTeamTable from "./components/PartnerAwsTeamTable";
import PartnerExecSponsorsTable from "./components/PartnerExecSponsorsTable";
import PartnerEventsTable from "./components/PartnerEventsTable";
import PartnerUsersTable from "./components/PartnerUsersTable";

import "../../styles/partnerStyles.scss";

const PartnerDetailPage = props => {
  const { match, history } = props;
  const { partnerId } = match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Partners",
          href: "/partners"
        },
        {
          text: "Partner Details",
          href: `/partners/${partnerId}`
        }
      ])
    );
  }, [dispatch, partnerId]);

  const [activeTabId, setActiveTabId] = useState("awsTeam");

  const tabs = [
    {
      label: "AWS Team",
      id: "awsTeam",
      content: <PartnerAwsTeamTable partnerId={partnerId} />
    },
    {
      label: "Executive Sponsors",
      id: "execSponsors",
      content: <PartnerExecSponsorsTable partnerId={partnerId} />
    },
    {
      label: "Users",
      id: "users",
      content: <PartnerUsersTable partnerId={partnerId} />
    },
    {
      label: "Scheduled Events",
      id: "scheduledEvents",
      content: <PartnerEventsTable
          partnerId={partnerId}
          eventStatus="Scheduled"
          history={history}
        />
    },
    {
      label: "Complete Events",
      id: "completeEvents",
      content: <PartnerEventsTable
          partnerId={partnerId}
          eventStatus="Complete"
          history={history}
        />
    },
    {
      label: "Canceled Events",
      id: "canceledEvents",
      content: <PartnerEventsTable
          partnerId={partnerId}
          eventStatus="Canceled"
          history={history}
        />
    }
  ];

  return (
    <>
      <PartnerDetails partnerId={partnerId} history={props.history} />
      <Tabs
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
      />
    </>
  );
};

export default PartnerDetailPage;
