import React, { useState, useEffect } from "react";

import {
  Button,
  Table,
  TableContentSelector,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TablePropertyFiltering,
  TableSorting,
  TableWrapLines
} from "@amzn/awsui-components-react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS,
  FILTERING_OPTIONS
} from "./CompleteEventsTableConfig.jsx";

import { API, graphqlOperation } from "aws-amplify";
import { listEventsWithDetail } from "../../../graphql/apnid-queries";

const CompleteEventsTable = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [completeEvents, setCompleteEvents] = useState([]);
  const [pageSize, setPageSize] = useState(30);

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async nextToken => {
    setLoading(true);
    let params = {
      limit: 1000,
      filter: { status: { eq: "Complete" } }
    };
    if (nextToken) params.nextToken = nextToken;

    const result = await API.graphql(
      graphqlOperation(listEventsWithDetail, params)
    );
    let items = result.data.listEvents.items;
    items = items.map(i => {
      return {
        ...i,
        partnerName: i.partner.name,
        eventOwner: `${i.user.firstName} ${i.user.lastName}`,
        eventOwnerEmail: i.user.email
      };
    });
    setCompleteEvents(completeEvents => completeEvents.concat(items));

    if (result.data.listEvents.nextToken) {
      fetchData(result.data.listEvents.nextToken);
    } else {
      setLoading(false);
    }
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  // Refresh function
  const refreshFn = () => {
    setCompleteEvents([]);
    fetchData("");
  };

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS(history)}
      items={completeEvents}
      loading={loading}
      loadingText="Loading..."
      header={<CompleteEventsTableHeader refreshFn={refreshFn} />}
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No complete</b>
          </div>
          <p className="awsui-util-mb-s">No complete events to display.</p>
        </div>
      }
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">No results match your filter.</p>
        </div>
      }
    >
      <TablePropertyFiltering
        filteringOptions={FILTERING_OPTIONS}
        groupValuesText="Values"
        groupPropertiesText="Properties"
        operationAndText="and"
        operationNotAndText="and not"
        operationOrText="or"
        operationNotOrText="or not"
        clearFiltersText="Clear filter"
        placeholder="Filter events by property or value"
        allowFreeTextFiltering={true}
        filteringCountTextFunction={count =>
          `${count} ${count === 1 ? "match" : "matches"}`
        }
      />
      <TablePagination
        onPaginationChange={onPaginationChange.bind(this)}
        pageSize={pageSize}
      />
      <TableSorting
        sortingColumn="date"
        sortingDescending={true}
        sortableColumns={SORTABLE_COLUMNS}
      />
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={PAGE_SELECTOR_OPTIONS}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Check to see all the text and wrap the lines"
          value={false}
        />
        <TableContentSelector
          title="Select visible columns"
          options={CONTENT_SELECTOR_OPTIONS}
        />
      </TablePreferences>
    </Table>
  );
};

const CompleteEventsTableHeader = ({ refreshFn }) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>Complete Events</h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" onClick={() => refreshFn()} />
      </div>
    </div>
  );
};

export default CompleteEventsTable;
