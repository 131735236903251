import React from "react";

// Route Helpers
import { Route, Switch } from "react-router-dom";
import {
  PublicRoute,
  PrivateRoute,
  DashboardRedirect
} from "./components/Routers";

// Pages
import { Login, LoginRedirect, Logout } from "./components/Auth";
import HomePage from "./pages/Home";
import FeedbackPage from "./pages/Feedback";
import {
  CompleteEventsPage,
  EventDetailsPage,
  EventsCalendarPage,
  ScheduledEventsPage
} from "./pages/Events";
import {
  CreatePartnerPage,
  EditPartnerDetailPage,
  ListPartnersPage,
  PartnerDetailPage
} from "./pages/Partners";

const AppRoutes = ({ props }) => {
  return (
    <Switch>
      {/* EXTERNAL ROUTES */}

      <Route path="/dashboard" component={DashboardRedirect} />

      {/* PUBLIC ROUTES */}

      <PublicRoute path="/auth/login-page" component={Login} props={props} />

      <PublicRoute
        path="/auth/login-redirect"
        component={LoginRedirect}
        props={props}
      />

      <PublicRoute path="/auth/logout-page" component={Logout} props={props} />

      {/* PRIVATE ROUTES */}

      <PrivateRoute path="/feedback" component={FeedbackPage} props={props} />

      <PrivateRoute
        path="/create-partner"
        component={CreatePartnerPage}
        props={props}
      />

      <PrivateRoute
        path="/partners/:partnerId/edit"
        component={EditPartnerDetailPage}
        props={props}
      />

      <PrivateRoute
        path="/partners/:partnerId"
        component={PartnerDetailPage}
        props={props}
      />

      <PrivateRoute
        path="/partners"
        component={ListPartnersPage}
        props={props}
      />

      <PrivateRoute
        path="/event/:eventId"
        component={EventDetailsPage}
        props={props}
      />

      <PrivateRoute
        path="/events-calendar"
        component={EventsCalendarPage}
        props={props}
      />

      <PrivateRoute
        path="/complete-events"
        component={CompleteEventsPage}
        props={props}
      />

      <PrivateRoute
        path="/scheduled-events"
        component={ScheduledEventsPage}
        props={props}
      />

      <PrivateRoute path="/" component={HomePage} props={props} />
    </Switch>
  );
};

export default AppRoutes;
