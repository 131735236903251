import React from "react";

export const formatListItems = (item) => {
  return <div key={item}>{item}</div>;
};

export const objIsEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * Ensure email address domain is in a valid format
 * @param {String} emailDomain
 * - Email address to verify
 * @return {Boolean}
 */
export function verifyEmailDomain(emailDomain) {
  var emailRex = /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(emailDomain)) {
    return true;
  }
  return false;
}

export function shortbread() {
  var shortbread = window.AWSCShortbread({
    parent: document.body,
    domain: ".".concat(window.location.hostname),
    onConsentChanged: function (c) { console.log("Cookie written", c) },
    queryGeolocation: function (geolocatedIn) { geolocatedIn("EU") }
  });
  shortbread.checkForCookieConsent();
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (date) => {
  const dateStr = new Date(date);
  return `${
    months[dateStr.getMonth()]
  } ${dateStr.getDate()}, ${dateStr.getFullYear()}`;
};

export const certificationOptions = [
  {
    id: "cloud-practitioner",
    label: "AWS Certified Cloud Practitioner",
  },
  {
    id: "associate-sa",
    label: "AWS Certified Solutions Architect - Associate",
  },
  {
    id: "associate-sysops",
    label: "AWS Certified SysOps Administrator - Associate",
  },
  {
    id: "associate-developer",
    label: "AWS Certified Developer - Associate",
  },
  {
    id: "professional-sa",
    label: "AWS Certified Solutions Architect - Professional",
  },
  {
    id: "professional-devops-engineer",
    label: "AWS Certified DevOps Engineer - Professional",
  },
  {
    id: "specialty-adv-networking",
    label: "AWS Certified Advanced Networking - Specialty",
  },
  {
    id: "specialty-big-data",
    label: "AWS Certified Big Data - Specialty",
  },
  {
    id: "specialty-database",
    label: "AWS Certified Database - Specialty",
  },
  {
    id: "specialty-security",
    label: "AWS Certified Security - Specialty",
  },
  {
    id: "specialty-machine-learning",
    label: "AWS Certified Machine Learning - Specialty",
  },
  {
    id: "specialty-alexa-skill-builder",
    label: "AWS Certified Alexa Skill Builder - Specialty",
  },
];

export const certificationLabelLookup = (val) => {
  const obj = certificationOptions.find((x) => x.id === val);
  return obj.label;
};

export const immersionDayTopicOptions = [
  { label: "General", id: "general" },
  { label: "AI/ML", id: "aiml" },
  { label: "Aurora", id: "aurora" },
  { label: "Containers", id: "containers" },
  { label: "Control Tower", id: "controltower" },
  { label: "Data Engineering", id: "dataeng" },
  { label: "Database Migration", id: "dbms" },
  { label: "DevOps", id: "devops" },
  { label: "DynamoDB", id: "dynamodb" },
  { label: "EC2 Spot", id: "ec2spot" },
  { label: "Elastic MapReduce", id: "emr" },
  { label: "IoT", id: "iot" },
  { label: "Landing Zone", id: "landingzone" },
  { label: "Microsoft", id: "microsoft" },
  { label: "Migration", id: "migration" },
  { label: "Redshift", id: "redshift" },
  { label: "Security & Compliance", id: "security" },
  { label: "Serverless", id: "serverless" },
  { label: "Serverless Data Lake", id: "svrless-data-lake" },
];

export const geoOptions = [
  { id: "1", label: "NA" },
  { id: "2", label: "APAC" },
  { id: "3", label: "China" },
  { id: "4", label: "EMEA" },
  { id: "5", label: "Japan" },
  { id: "6", label: "LATAM" },
];
