import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

export function PrivateRoute ({
  component: Component,
  props: childProps,
  ...rest
}) {
  const userIsAuthenticated = useSelector(state => state.app.userIsAuthenticated);

  if (userIsAuthenticated) {
    return (
      <Route
        {...rest}
        render={renderProps => <Component {...renderProps} {...childProps} />}
      />
    );
  }
  else window.location.href = "/auth/login-page";
}

export function PublicRoute ({
  component: Component,
  props: childProps,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={renderProps => <Component {...renderProps} {...childProps} />}
    />
  );
}

export const DashboardRedirect = () => {
  window.location.href = "https://idp.federate.amazon.com/api/saml2/v1/idp-initiated?providerId=prod.dashboard.apn.immersionday&target=https%3A%2F%2Fus-west-2.quicksight.aws.amazon.com%2Fsn%2Fdashboards%2F16a513d9-40ac-4f66-95b0-f67d8b5f1a92";
  return null;
}

export default null;
