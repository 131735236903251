import React, { useState, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { getPartnerAwsTeam } from "../../../graphql/apnid-queries";
import { updatePartner } from "../../../graphql/mutations";

import { addFlash } from "../../../actions";

import { addAwsTeamMemberForm } from "../../../reducers";

import {
  Button,
  ButtonDropdown,
  ColumnLayout,
  Table,
  TableSelection,
  Modal,
  Form,
  FormField,
  Input,
  Select
} from "@amzn/awsui-components-react";

import { AWSTEAM_COLUMN_DEFINITIONS } from "./PartnerAwsTeamTableConfig";

const DEFAULT_STATE = {
  fieldValues: {
    role: [],
    name: "",
    email: "",
    phoneno: ""
  },
  fieldStates: {
    role: false,
    name: false,
    email: false,
    phoneno: true
  },
  requiredFields: ["role", "name", "email"]
};

// New AWS Team Member form
const NewTeamMemberModal = ({ onCancel, onSubmit }) => {
  const [form, setForm] = useReducer(addAwsTeamMemberForm, DEFAULT_STATE);

  const roleOptions = [
    { id: "1", label: "PDM" },
    { id: "2", label: "PSM" },
    { id: "3", label: "PSA" }
  ];

  const formIsValid = () => {
    let formErrors = 0;

    let key;

    for (key in form.fieldStates) {
      if (form.fieldStates[key] === false) formErrors += 1;
    }

    return formErrors === 0 ? true : false;
  };

  return (
    <Modal
      header="Add AWS Team Member"
      visible={true}
      expandToFit={true}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!formIsValid()}
            onClick={() => {
              if (formIsValid()) onSubmit(form.fieldValues);
            }}
          >
            Add
          </Button>
        </span>
      }
    >
      <Form>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <FormField label="Role" description="PDM, PSM, or PSA">
              <Select
                options={roleOptions}
                selectedOption={form.fieldValues.role}
                invalid={
                  !(form.fieldStates.role === "" ? true : form.fieldStates.role)
                }
                onChange={({ detail }) =>
                  setForm({
                    type: "changeSelect",
                    payload: {
                      field: "role",
                      required: true,
                      value: detail.selectedOption
                    }
                  })
                }
              />
            </FormField>
            <FormField label="Name">
              <Input
                id="name"
                value={form.fieldValues.name}
                invalid={
                  !(form.fieldStates.name === "" ? true : form.fieldStates.name)
                }
                onInput={({ detail }) =>
                  setForm({
                    type: "changeInput",
                    payload: {
                      field: "name",
                      required: true,
                      value: detail.value
                    }
                  })
                }
              />
            </FormField>
            <FormField label="Email">
              <Input
                id="email"
                value={form.fieldValues.email}
                invalid={
                  !(form.fieldStates.email === ""
                    ? true
                    : form.fieldStates.email)
                }
                onInput={({ detail }) =>
                  setForm({
                    type: "changeEmail",
                    payload: {
                      field: "email",
                      value: detail.value
                    }
                  })
                }
              />
            </FormField>
            <FormField
              label={
                <span>
                  Phone <i>- optional</i>
                </span>
              }
            >
              <Input
                id="phoneno"
                value={form.fieldValues.phoneno}
                invalid={
                  !(form.fieldStates.phoneno === ""
                    ? true
                    : form.fieldStates.phoneno)
                }
                onInput={({ detail }) =>
                  setForm({
                    type: "changePhone",
                    payload: {
                      field: "phoneno",
                      value: detail.value
                    }
                  })
                }
              />
            </FormField>
          </div>
        </ColumnLayout>
      </Form>
    </Modal>
  );
};

const PartnerAwsTeamTable = ({ partnerId }) => {
  const [loading, setLoading] = useState(false);
  const [awsTeam, setAwsTeam] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const [modal, setModal] = useState(null);

  const dispatch = useDispatch();

  // Get data on component load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const result = await API.graphql(
      graphqlOperation(getPartnerAwsTeam, { id: partnerId })
    );
    let awsTeam = result.data.getPartner.awsTeam;
    if (!awsTeam) awsTeam = [];
    setAwsTeam(awsTeam);
    setLoading(false);
  };

  // Sets the selected user row
  const onSelectedTeamMemberChange = event => {
    setSelectedTeamMember(event.detail.selectedItems);
  };

  // Handle actions from the dropdown button
  const actionItemClicked = async event => {
    const thisTemMember = selectedTeamMember[0];

    if (event && event.detail) {
      switch (event.detail.id) {
        case "remove":
          let tempAwsTeam = awsTeam;
          const index = tempAwsTeam.findIndex(
            t => t.email === thisTemMember.email
          );
          tempAwsTeam.splice(index, 1);

          try {
            await API.graphql(
              graphqlOperation(updatePartner, {
                input: {
                  id: partnerId,
                  awsTeam: tempAwsTeam
                }
              })
            );

            dispatch(
              addFlash({
                type: "success",
                content: `${thisTemMember.name} removed from the AWS Team.`
              })
            );

            fetchData();
          } catch (err) {
            dispatch(
              addFlash({
                type: "error",
                header: "An error occurred.",
                content: err.errors[0].message
              })
            );
          }
          break;
        default:
          break;
      }
    }
  };

  // Sets the action button items
  const actionButtons = [
    {
      text: "Remove",
      id: "remove"
    }
  ];

  // Handle modal cancellation
  const onNewTeamMemberModalCancel = () => {
    setModal(null);
  };

  // Handle modal submission
  const onNewTeamMemberModalSubmit = async form => {
    setModal(null);

    // flatten form fields
    form = {
      ...form,
      role: form.role.label
    };
    if (form.phoneno === "") delete form.phoneno;

    // Add new user from form to the AWS Team object
    const newAwsTeam = [...awsTeam, form];

    try {
      // Save the AWS Team obejct to the partners DynamoDB record
      await API.graphql(
        graphqlOperation(updatePartner, {
          input: {
            id: partnerId,
            awsTeam: newAwsTeam
          }
        })
      );

      dispatch(
        addFlash({
          type: "success",
          content: `${form.name} added to the AWS Team.`
        })
      );

      fetchData();
    } catch (err) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred.",
          content: err.errors[0].message
        })
      );
    }
  };

  return (
    <div>
      {modal}
      <div className="awsui-util-mb-l">
        <Table
          columnDefinitions={AWSTEAM_COLUMN_DEFINITIONS}
          loading={loading}
          loadingText="Loading..."
          items={awsTeam}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>AWS Team</h2>
              </div>
              <div className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  items={actionButtons}
                  onItemClick={e => actionItemClicked(e)}
                  disabled={selectedTeamMember.length ? false : true}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  icon="add-plus"
                  onClick={() => {
                    setModal(
                      <NewTeamMemberModal
                        onCancel={onNewTeamMemberModalCancel}
                        onSubmit={onNewTeamMemberModalSubmit}
                      />
                    );
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          }
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No AWS team</b>
              </div>
              <p className="awsui-util-mb-s">No AWS Team to display.</p>
            </div>
          }
        >
          <TableSelection
            selectionType="single"
            selectedItems={selectedTeamMember}
            onSelectionChange={onSelectedTeamMemberChange.bind(this)}
          />
        </Table>
      </div>
    </div>
  );
};

export default PartnerAwsTeamTable;
