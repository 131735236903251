import produce from "immer";
import { objIsEmpty } from "../utils";

const validator = require("validator");

const addPartnerUserForm = (form, action) => {
  const field = action.payload.field;
  const value = action.payload.value;

  return produce(form, draft => {
    switch (action.type) {
      case "changeInput":
        if (action.payload.required)
          draft.fieldStates[field] = !validator.isEmpty(value) ? true : false;
        draft.fieldValues[field] = value;
        break;

      case "changeSelect":
        if (action.payload.required)
          draft.fieldStates[field] = !objIsEmpty(value) ? true : false;
        draft.fieldValues[field] = value;
        break;

      default:
        draft = action.payload;
        break;
    }
  });
};

export default addPartnerUserForm;
