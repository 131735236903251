import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import PartnersTable from "./components/PartnersTable";

const ListPartnersPage = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Partners",
          href: "/partners"
        }
      ])
    );
  }, [dispatch]);

  return (
    <>
      <PartnersTable history={props.history} />
    </>
  );
};

export default ListPartnersPage;
