import Moment from 'moment';

export const COLUMN_DEFINITIONS = [
  {
    id: "id",
    header: () => "ID",
    cell: item => item.id,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "name",
    header: () => "User Name",
    cell: item => `${item.user.firstName} ${item.user.lastName}`,
    minWidth: "180px",
    allowLineWrap: true
  },
  {
    id: "email",
    cell: item => item.user.email,
    header: () => "Email",
    minWidth: "160px",
    allowLineWrap: true
  },
  {
    id: "comment",
    header: () => "Feedback Comment",
    cell: item => item.feedback,
    minWidth: "160px",
    allowLineWrap: true
  },
  {
    id: "page",
    header: () => "Page",
    cell: item => item.page,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "createdAt",
    cell: item => Moment(item.createdAt).format('L'),
    header: () => "Created",
    minWidth: "160px",
    allowLineWrap: true
  },
];

export const SORTABLE_COLUMNS = [
  { id: "name", field: "firstName" },
  { id: "email", field: "email" },
  { id: "createdAt", field: "createdAt"}
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Table settings",
    options: [
      { id: "id", label: "ID", editable: false, visible: false },
      { id: "name", label: "Name", editable: true, visible: true },
      { id: "email", label: "Email", editable: true, visible: true },
      { id: "comment", label: "Comment", editable: true, visible: true },
      { id: "page", label: "Page", editable: true, visible: true },
      { id: "createdAt", label: "Created", editable: true, visible: true}
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" }
];
