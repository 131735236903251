import React from "react";
import { withRouter } from "react-router-dom";
import { SideNavigation } from "@amzn/awsui-components-react";

import logo from "../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../styles/navigation.scss";

class Navigation extends React.Component {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href && ev.detail.external) {
      window.open(ev.detail.href, "_blank");
    } else if (ev.detail.href) {
      this.props.history.push(ev.detail.href);
    }
  }

  render() {
    return (
      <div>
        <img
          className="nav-logo"
          src={logo}
          alt="APN Immersion Day"
          onClick={() => this.props.history.push("/")}
        />
        <SideNavigation
          items={items}
          activeHref={`${this.props.location.pathname}`}
          onFollow={this.onFollowHandler.bind(this)}
        />
      </div>
    );
  }
}

const externalHrefs = {
  quicksight:
    "https://idp.federate.amazon.com/api/saml2/v1/idp-initiated?providerId=prod.dashboard.apn.immersionday&target=https%3A%2F%2Fus-west-2.quicksight.aws.amazon.com%2Fsn%2Fdashboards%2F16a513d9-40ac-4f66-95b0-f67d8b5f1a92",
  programPage: "https://aws.amazon.com/partners/immersion-days/"
};

const items = [
  { type: "divider" },
  {
    type: "link",
    text: "QuickSight Dashboard",
    href: externalHrefs.quicksight,
    external: true
  },
  {
    type: "link",
    text: "Public Program Page",
    href: externalHrefs.programPage,
    external: true
  },
  { type: "divider" },
  { type: "link", text: "Partners", href: "/partners" },
  { type: "divider" },
  {
    type: "section",
    expanded: false,
    text: "Events",
    items: [
      { type: "link", text: "Calendar", href: "/events-calendar" },
      { type: "link", text: "Scheduled", href: "/scheduled-events" },
      { type: "link", text: "Complete", href: "/complete-events" }
    ]
  },
  { type: "divider" },
  { type: "link", text: "Feedback", href: "/feedback" }
];

export default withRouter(Navigation);
