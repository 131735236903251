import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import CreatePartnerForm from "./components/CreatePartnerForm";

const CreatePartnerPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadCrumbs([
      {
        text: "APN Immersion Day",
        href: "/"
      },
      {
        text: "Partners",
        href: "/partners"
      },
      {
        text: "Create Partner",
        href: "/create-partner"
      }
    ]));
  }, [dispatch])
  
  return (
    <>
      <CreatePartnerForm />
    </>
  );
}

export default CreatePartnerPage;
