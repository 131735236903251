import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { store } from "./reducers";

// Route Helpers
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import Amplify, { Auth as AmplifyAuth, Storage, Cache } from "aws-amplify";
import config from "./awsconfiguration.json";

import App from "./App";

import "./styles/index.scss";

/**************************************************************
 * History Setup
 *************************************************************/

const history = createBrowserHistory();

/**************************************************************
 * Amplify Configuration
 *
 * Configure the AWS Amplify SDK components that will be used
 *    throughout the app.
 * The config file is created during the build process.
 * For local testing, create a local copy of aws-exports.js.
 *************************************************************/

Amplify.configure({
  ...config,
  Auth: {
    mandatorySignIn: true,
    identityPoolId: config.aws_cognito_identity_pool_id
  },
  graphql_headers: async () => {
    const currentSession = await AmplifyAuth.currentSession();
    return {
      Authorization: currentSession.getIdToken().getJwtToken(),
      "Cache-Control": "no-cache, no-store",
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains"
    };
  }
});
Storage.configure({ level: "public" });
Cache.configure({
  defaultTTL: 31556952000 // 1 year
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
