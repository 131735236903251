import React, { useState, useReducer, createRef } from "react";
import { useDispatch } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { createPartner } from "../../../graphql/mutations";

import { addFlash } from "../../../actions";
import { createPartnerForm } from "../../../reducers";
import { verifyEmailDomain } from "../../../utils";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Modal,
  Select,
  TokenGroup
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    id: "",
    spmsId: "",
    name: "",
    headquarters: "",
    geo: [],
    emailDomainInput: "@",
    emailDomain: [],
    coverageAreasInput: "",
    coverageAreas: [],
    specialtyDomainsInput: "",
    specialtyDomains: []
  },
  fieldStates: {
    id: false,
    spmsId: false,
    name: false,
    headquarters: false,
    geo: false,
    emailDomain: false
  },
  requiredFields: ["id", "spmsId", "name", "headquarters", "geo", "emailDomain"]
};

const CreatePartnerForm = () => {
  const [form, setForm] = useReducer(createPartnerForm, DEFAULT_STATE);
  const [modal, setModal] = useState(null);

  const dispatch = useDispatch();
  const mainPanel = createRef();

  const geoOptions = [
    { id: "1", label: "NA" },
    { id: "2", label: "APAC" },
    { id: "3", label: "China" },
    { id: "4", label: "EMEA" },
    { id: "5", label: "Japan" },
    { id: "6", label: "LATAM" }
  ];

  const changeEmailDomainTokens = newDomain => {
    if (verifyEmailDomain(newDomain)) {
      setForm({
        type: "addToken",
        required: true,
        payload: {
          field: "emailDomain",
          value: newDomain
        }
      });
    } else {
      setModal(
        <Modal
          header="Error!"
          visible={true}
          footer={
            <span className="awsui-util-f-r">
              <Button variant="link" onClick={() => setModal(null)}>
                Close
              </Button>
            </span>
          }
        >
          Must be a valid email domain that begins with @
        </Modal>
      );
    }
  };

  const formIsValid = () => {
    let formErrors = 0;

    let key;

    for (key in form.fieldStates) {
      let keyIsRequired = form.requiredFields.indexOf(key) > -1 ? true : false;

      if (
        keyIsRequired &&
        (form.fieldStates[key] === false || form.fieldStates[key] === "")
      )
        formErrors += 1;
      if (form.fieldStates[key] === false) formErrors += 1;
    }

    return formErrors === 0 ? true : false;
  };

  const submitForm = async () => {
    let input = { ...form.fieldValues };

    const flattenTokenList = list => {
      let tList = [];
      list.forEach(item => tList.push(item.label));
      return tList;
    };

    // delete unwanted fields
    delete input.emailDomainInput;
    delete input.coverageAreasInput;
    delete input.specialtyDomainsInput;

    // flatten fields from form
    input.geo = input.geo.label;
    input.emailDomain = flattenTokenList(input.emailDomain);
    input.coverageAreas = flattenTokenList(input.coverageAreas);
    input.specialtyDomains = flattenTokenList(input.specialtyDomains);

    try {
      await API.graphql(
        graphqlOperation(createPartner, {
          input: input
        })
      );
      window.location.href = `/partners/${input.id}`;
    } catch (err) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred.",
          content: err.errors[0].message
        })
      );
    }
  };

  return (
    <div ref={mainPanel} id="mainPanel">
      {modal}
      <Form
        header={
          <span>
            <h1 className="awsui-util-d-ib">Create Partner</h1>
          </span>
        }
        description="Create a record for a partner new to the APN Immersion Day program."
        actions={
          <div>
            <Button
              variant="link"
              onClick={() => (window.location.href = "/partners")}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (formIsValid()) submitForm();
                else {
                  setModal(
                    <Modal
                      header="Error!"
                      visible={true}
                      footer={
                        <span className="awsui-util-f-r">
                          <Button variant="link" onClick={() => setModal(null)}>
                            Close
                          </Button>
                        </span>
                      }
                    >
                      The form has missing or invalid fields.
                    </Modal>
                  );
                }
              }}
            >
              Create partner
            </Button>
          </div>
        }
      >
        <FormSection
          id="partner-sfdc-panel"
          header={<h2>Partner SFDC Info</h2>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="Partner ID"
                description="Obtain from apn-portal.my.salesforce.com"
              >
                <Input
                  id="id"
                  value={form.fieldValues.id}
                  invalid={
                    !(form.fieldStates.id === "" ? true : form.fieldStates.id)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      required: true,
                      payload: {
                        field: "id",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="SPMS ID"
                description="Obtain from apn-portal.my.salesforce.com"
              >
                <Input
                  id="spmsId"
                  value={form.fieldValues.spmsId}
                  invalid={
                    !(form.fieldStates.spmsId === ""
                      ? true
                      : form.fieldStates.spmsId)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      required: true,
                      payload: {
                        field: "spmsId",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Company Name"
                description="Obtain from apn-portal.my.salesforce.com"
              >
                <Input
                  id="name"
                  value={form.fieldValues.name}
                  invalid={
                    !(form.fieldStates.name === ""
                      ? true
                      : form.fieldStates.name)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      required: true,
                      payload: {
                        field: "name",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField label="Headquarters Location">
                <Input
                  id="headquarters"
                  value={form.fieldValues.headquarters}
                  invalid={
                    !(form.fieldStates.headquarters === ""
                      ? true
                      : form.fieldStates.headquarters)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      required: true,
                      payload: {
                        field: "headquarters",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField label="Partner Geo">
                <Select
                  options={geoOptions}
                  selectedOption={form.fieldValues.geo}
                  invalid={
                    !(form.fieldStates.geo === "" ? true : form.fieldStates.geo)
                  }
                  onChange={({ detail }) => {
                    setForm({
                      type: "change",
                      required: true,
                      payload: {
                        field: "geo",
                        value: detail.selectedOption
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>

        <FormSection
          id="partner-domain-panel"
          header={<h2>Email Domains</h2>}
          description={
            <span>
              There must be at least one domain.
              <br />
              Domains must be in the form of: @domain.com
              <br />
              Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
              Values are not saved unless they appear in the token group below
              the input box.
            </span>
          }
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField label="Email Domains">
                <Input
                  id="emailDomain"
                  value={form.fieldValues.emailDomainInput}
                  invalid={
                    !(form.fieldStates.emailDomain === ""
                      ? true
                      : form.fieldStates.emailDomain)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      payload: {
                        field: "emailDomainInput",
                        value: detail.value
                      }
                    });
                  }}
                  onChange={({ detail }) =>
                    changeEmailDomainTokens(detail.value)
                  }
                />
                <TokenGroup
                  items={form.fieldValues.emailDomain}
                  onDismiss={({ detail }) => {
                    setForm({
                      type: "dismissToken",
                      required: true,
                      payload: {
                        field: "emailDomain",
                        value: detail.itemIndex
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>

        <FormSection
          id="partner-coverage-areas-panel"
          header={<h2>Coverage Areas</h2>}
          description={
            <span>
              Specify the coverage areas of the partner.
              <br />
              A coverage area should include: City, State, Country
              <br />
              Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
              Values are not saved unless they appear in the token group below
              the input box.
            </span>
          }
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label={
                  <span>
                    Coverage Areas <i>- optional</i>
                  </span>
                }
              >
                <Input
                  id="coverageAreas"
                  value={form.fieldValues.coverageAreasInput}
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      payload: {
                        field: "coverageAreasInput",
                        value: detail.value
                      }
                    });
                  }}
                  onChange={({ detail }) => {
                    setForm({
                      type: "addToken",
                      validation: "required",
                      payload: {
                        field: "coverageAreas",
                        value: detail.value
                      }
                    });
                  }}
                />
                <TokenGroup
                  items={form.fieldValues.coverageAreas}
                  onDismiss={({ detail }) => {
                    setForm({
                      type: "dismissToken",
                      payload: {
                        field: "coverageAreas",
                        value: detail.itemIndex
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>

        <FormSection
          id="partner-specialty-panel"
          header={<h2>Specialty Domains</h2>}
          description={
            <span>
              Specialty domains are areas of expertise for the partner.
              <br />
              These may be areas such as Serverless, Machine Learning,
              Migrations, etc.
              <br />
              Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
              Values are not saved unless they appear in the token group below
              the input box.
            </span>
          }
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label={
                  <span>
                    Specialty Domains <i>- optional</i>
                  </span>
                }
              >
                <Input
                  id="specialtyDomains"
                  value={form.fieldValues.specialtyDomainsInput}
                  onInput={({ detail }) => {
                    setForm({
                      type: "change",
                      payload: {
                        field: "specialtyDomainsInput",
                        value: detail.value
                      }
                    });
                  }}
                  onChange={({ detail }) => {
                    setForm({
                      type: "addToken",
                      validation: "required",
                      payload: {
                        field: "specialtyDomains",
                        value: detail.value
                      }
                    });
                  }}
                />
                <TokenGroup
                  items={form.fieldValues.specialtyDomains}
                  onDismiss={({ detail }) => {
                    setForm({
                      type: "dismissToken",
                      payload: {
                        field: "specialtyDomains",
                        value: detail.itemIndex
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default CreatePartnerForm;
