import produce from "immer";
import { v4 as uuidv4 } from "uuid";

export const initialState = {
  breadcrumbItems: [],
  flashbarItems: [],
  currentUser: {},
  userIsAuthenticating: true,
  userIsAuthenticated: false
};

const app = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      // ---------------------------
      // AUTHENTICATION
      // ---------------------------
      case "USER_SETCURRENTUSER":
        draft.currentUser = action.payload;
        break;

      case "USER_AUTHENTICATING":
        draft.userIsAuthenticating = action.payload;
        break;

      case "USER_AUTHENTICATED":
        draft.userIsAuthenticated = action.payload;
        break;

      case "USER_RESET":
        draft.currentUser = action.payload;
        break;

      // ---------------------------
      // BREADCRUMBS
      // ---------------------------
      case "UPDATE_BREADCRUMBS":
        if (
          action.payload.length !== state.breadcrumbItems.length ||
          action.payload.slice(-1)[0].text !==
            state.breadcrumbItems.slice(-1)[0].text
        ) {
          // only clear flash items if last breadcrumb changed
          draft.flashbarItems = [];
        }
        draft.breadcrumbItems = action.payload;
        break;

      case "ADD_BREADCRUMB":
        draft.flashbarItems = [];
        draft.breadcrumbItems.push(action.payload);
        break;

      case "POP_BREADCRUMB":
        draft.breadcrumbItems.pop();
        draft.flashbarItems = [];
        break;

      // ---------------------------
      // FLASH MESSAGES
      // ---------------------------
      case "ADD_FLASH":
        draft.flashbarItems.push({
          ...action.payload,
          id: uuidv4(),
          dismissible: true
        });
        break;

      case "REMOVE_FLASH":
        let flashIndex = draft.flashbarItems.findIndex(
          f => f.id === action.payload[0]
        );
        draft.flashbarItems.splice(flashIndex, 1);
        break;

      default:
        break;
    }
  });
};

export default app;
